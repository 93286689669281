import React, { ChangeEvent, useRef, useState } from 'react'
import { Button, ButtonGroup, Modal, ToggleButton } from 'react-bootstrap'
import { useReactToPrint } from 'react-to-print'
import 'css/print-modal.css'
import Overview from 'components/organisms/print/Overview'
import { useSelecter } from 'stores'
import Projects from './print/Projects'
import { Project } from 'interfaces'
import { useTranslation } from 'react-i18next'

interface PreviewModalProps {
  show: boolean
  handleClose: () => void
}

const sliceProjectsByNumber = (
  projects: Project[],
  number: number
): Project[][] => {
  const length = Math.ceil(projects.length / number)
  return new Array(length).fill(0).map((_, i) => {
    return projects.slice(i * number, (i + 1) * number)
  })
}

function PreviewModal({
  show,
  handleClose,
}: PreviewModalProps): React.ReactElement {
  const printTargetRef = useRef<HTMLDivElement>(null)
  const [identification, setIdentification] = useState('name')
  const a4height = 297
  const { t } = useTranslation()

  const handlePrint = useReactToPrint({
    content: () => printTargetRef.current,
  })

  const { projects } = useSelecter((state) => {
    return state
  })

  const projectsGroup = sliceProjectsByNumber(projects.list, 3)

  const onNameOptionToggleButton = (e: ChangeEvent<HTMLInputElement>): void => {
    setIdentification(e.currentTarget.value)
    console.log(e.currentTarget.value)
  }

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-width">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <ButtonGroup>
            {[
              {
                value: 'name',
                name: t('print.label.basic.name'),
              },
              {
                value: 'initial',
                name: t('print.label.basic.initial'),
              },
            ].map((nameOption, i) => (
              <ToggleButton
                key={i}
                id={`identification-${i}`}
                onChange={onNameOptionToggleButton}
                type="radio"
                variant="outline-secondary"
                checked={nameOption.value === identification}
                size="sm"
                value={nameOption.value}
              >
                {nameOption.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        {/* Print Area start */}
        <div
          ref={printTargetRef}
          style={{
            width: '210mm',
            height: (a4height * (projectsGroup.length + 1)).toString() + 'mm',
          }}
        >
          <Overview identification={identification} />
          {projectsGroup.map((projects, i) => (
            <Projects key={i} projects={projects} />
          ))}
        </div>
        {/* Print Area end */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handlePrint}>印刷</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PreviewModal
