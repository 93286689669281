import { createSlice } from '@reduxjs/toolkit'
import { Project } from 'interfaces'
import { v4 as uuidv4 } from 'uuid'

const initialState: {
  list: Project[]
} = {
  list: [
    {
      uuid: uuidv4(),
      programs: [],
      term: '1',
      tools: [],
      industry: '',
      roles: [],
      phases: [],
      remarks: '',
    },
  ],
}

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    addProject: (state, action) => {
      state.list = [
        ...state.list,
        {
          uuid: action.payload.uuid,
          programs: action.payload.programs,
          term: action.payload.term,
          tools: action.payload.tools,
          industry: action.payload.industry,
          roles: action.payload.roles,
          phases: action.payload.phases,
          remarks: action.payload.remarks,
        },
      ]
    },
    updateProject: (state, action) => {
      state.list = state.list.map((project: Project) => {
        if (project.uuid === action.payload.uuid) {
          return {
            uuid: project.uuid,
            programs: action.payload.programs,
            term: action.payload.term,
            tools: action.payload.tools,
            industry: action.payload.industry,
            roles: action.payload.roles,
            phases: action.payload.phases,
            remarks: action.payload.remarks,
          }
        }
        return project
      })
    },
    deleteProject: (state, action) => {
      state.list = state.list.filter(
        (project: Project) => project.uuid !== action.payload.uuid
      )
    },
    resetProjects: (state) => {
      state.list = initialState.list
    },
    clearProjects: (state) => {
      state.list = []
    },
  },
})

export const {
  addProject,
  updateProject,
  deleteProject,
  resetProjects,
  clearProjects,
} = slice.actions

export default slice.reducer
