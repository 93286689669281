import React from 'react'
import { Container } from 'react-bootstrap'
import ExperienceArea from './components/organisms/area/ExperienceArea'
import BasicArea from './components/organisms/area/BasicArea'
import StudyArea from './components/organisms/area/StudyArea'
import ProjectArea from './components/organisms/area/ProjectArea'
import MainNavbar from './components/organisms/MainNavbar'

// const App: React.FC = () => {
function App(): React.ReactElement {
  return (
    <>
      <MainNavbar />
      <Container>
        <BasicArea />
        <StudyArea />
        <ExperienceArea />
        <ProjectArea />
      </Container>
    </>
  )
}

export default App
