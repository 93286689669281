import React from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'
import { Experience, ExperienceGroup as ExperienceGroupType } from 'interfaces'
import ExperienceRow from '../../molecular/row/ExperienceRow'
import StackedHorizontalBar from '../StackedHorizontalBar'
import { FaPlus } from 'react-icons/fa'
import { useDispatch } from 'stores'
import { updateExperienceGroup } from 'stores/experiences'
import { v4 as uuidv4 } from 'uuid'

interface ExperienceGroupProps extends ExperienceGroupType {
  //
}

function ExperienceGroup({
  index,
  title,
  experiences,
}: ExperienceGroupProps): React.ReactElement {
  const dispach = useDispatch()

  const onChangeExperienceRow = (experience: Experience): void => {
    dispach(
      updateExperienceGroup({
        index,
        title,
        experiences: experiences.map((target) => {
          if (target.uuid === experience.uuid) {
            return {
              ...experience,
            }
          }
          return target
        }),
      })
    )
  }

  const onClickAdd = (): void => {
    dispach(
      updateExperienceGroup({
        index,
        title,
        experiences: [
          ...experiences,
          {
            uuid: uuidv4(),
            name: '',
            value: 0,
          },
        ],
      })
    )
  }

  const deleteRow = (uuid: string): void => {
    dispach(
      updateExperienceGroup({
        index,
        title,
        experiences: experiences.filter((target) => target.uuid !== uuid),
      })
    )
  }

  return (
    <>
      <Row>
        <Col>
          <StackedHorizontalBar labels={[title]} experiences={experiences} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>{title}</Form.Label>
        </Col>
      </Row>
      {experiences.map((experience) => {
        return (
          <ExperienceRow
            key={experience.uuid}
            onChangeRow={onChangeExperienceRow}
            deleteRow={deleteRow}
            {...experience}
          />
        )
      })}
      <Row className="mt-4 mb-4">
        <Col className="text-center">
          {experiences.length < 10 && (
            <Button variant="light" onClick={onClickAdd}>
              <FaPlus />
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default ExperienceGroup
