import StudyRow from 'components/molecular/row/StudyRow'
import React from 'react'
import { Card, Col, Row, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { updateStudy, addStudy, deleteStudy } from 'stores/studies'
import { useDispatch, useSelecter } from 'stores'
import { v4 as uuidv4 } from 'uuid'
import { Study } from 'interfaces'

function StudyArea(): React.ReactElement {
  const { list } = useSelecter((state) => {
    return state.studies
  })
  const dispach = useDispatch()
  const { t } = useTranslation()

  const onChangeStudyRow = (study: Study): void => {
    dispach(updateStudy(study))
  }

  const onClickAdd = (): void => {
    dispach(
      addStudy({
        uuid: uuidv4(),
        name: '',
        date: '',
        remarks: '',
      })
    )
  }

  const deleteRow = (uuid: string): void => {
    dispach(deleteStudy({ uuid }))
  }

  return (
    <>
      <div className="card-header">
        <h1 className="c-title--head">{t('study.name')}</h1>
        <p className="lead">{t('study.description')}</p>
      </div>
      <Card className="mt-4">
        <Card.Body>
          {list.map((study) => {
            return (
              <StudyRow
                key={study.uuid}
                {...study}
                onChangeRow={onChangeStudyRow}
                deleteRow={deleteRow}
              />
            )
          })}
          <Row>
            <Col className="text-center">
              {list.length < 5 && (
                <Button variant="light" onClick={onClickAdd}>
                  <FaPlus />
                </Button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default StudyArea
