import { Experience } from 'interfaces'
import React from 'react'
import styled from 'styled-components'
import { adjustedExperienceGroups } from './calc'

interface GridRollProps {
  height?: string
  column: number
}

const GridRoll = styled.div<GridRollProps>`
  flex: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.column}, 1fr);
  grid-template-rows: 1fr;
  /* 横向きのグリッドラインを決める */
  height: ${(props) => props.height ?? '50px'};
  /* 高さ指定 */
  width: 100%;
`

interface GridRollChildProps {
  number: number
  column: string
  extra?: string
}

const GridRollChild = styled.div<GridRollChildProps>`
  :nth-child(${(props) => props.number}) {
    background-size: 10px 10px;
    background-image: radial-gradient(#d4145a 1px, #fff 1px);
    background-color: rgba(255, 255, 255, 0.9);
    background-blend-mode: lighten;
    color: #d4145a;
    border: 2px solid #d4145a;
    /* 背景色 */
    grid-column: ${(props) => props.column};
    /* 配置指定 */
    ${(props) => {
      if (props.extra === 'first') {
        return 'border-top-left-radius: 5px;border-bottom-left-radius: 5px;'
      }
      if (props.extra === 'last') {
        return 'border-top-right-radius: 5px;border-bottom-right-radius: 5px;border-right: 2px solid #d4145a !important;'
      }
      if (props.extra === 'both') {
        return 'border-top-left-radius: 5px;border-bottom-left-radius: 5px; border-top-right-radius: 5px;border-bottom-right-radius: 5px;border-right: 2px solid #d4145a !important;'
      }
    }}
  }
  /* 周りの余白指定 */
  color: #fff;
  /* 文字色指定 */
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  border-right: none !important;
`

interface RollProps {
  experiences: Experience[]
}

const Roll = ({ experiences }: RollProps): React.ReactElement => {
  const items = adjustedExperienceGroups(experiences, experiences.length)

  return (
    <GridRoll column={10}>
      {items.map((experience, i) => {
        return (
          <GridRollChild
            key={i}
            number={i + 1}
            extra={
              items.length === 1
                ? 'both'
                : i === 0
                ? 'first'
                : i === items.length - 1
                ? 'last'
                : ''
            }
            column={experience.column}
          >
            {experience.name}
          </GridRollChild>
        )
      })}
    </GridRoll>
  )
}

export default Roll
