import React, { useState } from 'react'
import { Container, Modal, Nav, Navbar } from 'react-bootstrap'
import FileUpload from './fileUpload'
import { useTranslation } from 'react-i18next'
import { useReset } from 'services/hooks/reset'
import { useDownload } from 'services/hooks/download'
import { useLoadSample } from 'services/hooks/loadSample'
import PreviewModal from './PreviewModal'
import {
  FaPencilAlt,
  FaFileUpload,
  FaPrint,
  FaDownload,
  FaBookmark,
} from 'react-icons/fa'
import 'css/icon.css'
import logo from 'assets/images/logo.svg'

interface UploadModalProps {
  show: boolean
  handleClose: () => void
}

function UploadModal({
  show,
  handleClose,
}: UploadModalProps): React.ReactElement {
  const { t } = useTranslation()
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('file-upload-modal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileUpload />
        </Modal.Body>
      </Modal>
    </>
  )
}

function MainNavbar(): React.ReactElement {
  const { t } = useTranslation()
  const [uploadShow, setUploadShow] = useState(false)
  const [previewShow, setPreviewShow] = useState(false)
  const [resetAll] = useReset()
  const [downloadFile] = useDownload()
  const [loadSample] = useLoadSample()

  const handleUploadModalClose = (): void => setUploadShow(false)
  const handlePreviewModalClose = (): void => setPreviewShow(false)

  const handleSelect = (eventKey: any): void => {
    switch (eventKey) {
      case 'upload':
        setUploadShow(true)
        break
      case 'reset':
        resetAll()
        break
      case 'download':
        downloadFile()
        break
      case 'preview':
        setPreviewShow(true)
        break
      case 'sample':
        loadSample()
        break
    }
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand>
          <img src={logo} height="15" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" onSelect={handleSelect}>
            <Nav.Link eventKey="reset">
              <FaPencilAlt className="nav-icon" />
              {t('header.create-new')}
            </Nav.Link>
            <Nav.Link eventKey="preview">
              <FaPrint className="nav-icon" />
              {t('header.transform-pdf')}
            </Nav.Link>
            <Nav.Link eventKey="download">
              <FaDownload className="nav-icon" />
              {t('header.file-download')}
            </Nav.Link>
            <Nav.Link eventKey="sample">
              <FaBookmark className="nav-icon" />
              {t('header.sample')}
            </Nav.Link>
            <PreviewModal
              show={previewShow}
              handleClose={handlePreviewModalClose}
            />
          </Nav>
          <Nav onSelect={handleSelect}>
            <Nav.Link eventKey="upload">
              <FaFileUpload className="nav-icon" /> {t('header.file-upload')}
            </Nav.Link>
            <UploadModal
              show={uploadShow}
              handleClose={handleUploadModalClose}
            />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default MainNavbar
