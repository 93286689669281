import { FileFormat } from 'interfaces'
import { useSelecter } from 'stores'
import yaml from 'js-yaml'
import { format } from 'functions/datetime'

export const useDownload = (): [() => void] => {
  const { basic, studies, experiences, projects } = useSelecter((state) => {
    return state
  })

  const downloadFile = (): void => {
    const date = new Date()

    const fileFormat: FileFormat = {
      createdAt: format({ date }),
      updatedAt: '',
      basic: basic.values,
      studies: studies.list.filter((study) => study.name !== ''),
      experienceGroups: experiences.groups,
      projects: projects.list.filter(
        (project) => project.programs.length !== 0
      ),
    }

    const text = yaml.dump(fileFormat)

    const fileName = format({ date, narrow: true }) + '.yaml'
    const data = new Blob([text], { type: 'text/yaml' })
    const link = document.createElement('a')
    const url = window.URL.createObjectURL(data)
    document.body.appendChild(link)
    link.href = url
    link.setAttribute('download', fileName)
    link.click()
    document.body.removeChild(link)
  }

  return [downloadFile]
}
