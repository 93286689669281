interface formatProps {
  date: Date
  narrow?: boolean // セパレータを排除 ex. 20221010T091010
}

export function format({ date, narrow = false }: formatProps): string {
  const formatted = date.toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })

  if (narrow) {
    return formatted.replace(' ', 'T').replaceAll('/', '').replaceAll(':', '')
  } else {
    return formatted.split('/').join('-')
  }
}
