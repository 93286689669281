import { Study } from 'interfaces'
import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaTrash } from 'react-icons/fa'

interface StudyRowProps extends Study {
  onChangeRow: (study: Study) => void
  deleteRow: (uuid: string) => void
}

function StudyRow({
  uuid,
  name,
  date,
  remarks,
  onChangeRow,
  deleteRow,
}: StudyRowProps): React.ReactElement {
  const { t } = useTranslation()

  const onChangeValue = (e: React.ChangeEvent): void => {
    const newValue = (e.target as HTMLInputElement).value
    const targetName = (e.target as HTMLInputElement).name

    onChangeRow({
      ...{
        uuid,
        name,
        date,
        remarks,
      },
      [targetName]: newValue,
    })
  }

  const onClickDeleteBtn = (e: React.MouseEvent): void => {
    deleteRow(uuid)
  }

  return (
    <Row className="mb-4">
      <Col xs={12} md={8} className="mb-2 mb-md-0">
        <Form.Label>{t('study.label.name')}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t('study.placeholder.name')}
          value={name}
          name="name"
          onChange={onChangeValue}
        />
      </Col>
      <Col xs={12} md={3} className="mb-2 mb-md-0">
        <Form.Label>{t('study.label.date')}</Form.Label>
        <Form.Control
          type="date"
          value={date}
          name="date"
          onChange={onChangeValue}
        />
      </Col>
      <Col
        xs={12}
        md={1}
        className="d-flex justify-content-center align-items-end"
      >
        <Button variant="light" onClick={onClickDeleteBtn}>
          <FaTrash />
        </Button>
      </Col>
    </Row>
  )
}

export default StudyRow
