import { useDispatch } from 'stores'
import { resetBasic } from 'stores/basic'
import { resetStudies } from 'stores/studies'
import { resetExperienceGroups } from 'stores/experiences'
import { resetProjects } from 'stores/projects'

export const useReset = (): [resetAll: () => void] => {
  const dispach = useDispatch()

  const resetAll = (): void => {
    dispach(resetBasic())
    dispach(resetStudies())
    dispach(resetExperienceGroups())
    dispach(resetProjects())
  }

  return [resetAll]
}
