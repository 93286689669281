import { Project } from 'interfaces'
import React from 'react'
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap'
import { InputTags } from 'components/organisms/customInputTags'
import { useTranslation } from 'react-i18next'
import { FaTrash } from 'react-icons/fa'

interface ProjectRowProps extends Project {
  onChangeRow: (project: Project) => void
  deleteRow: (uuid: string) => void
}

function ProjectRow({
  uuid,
  programs,
  term,
  tools,
  industry,
  roles,
  phases,
  remarks,
  onChangeRow,
  deleteRow,
}: ProjectRowProps): React.ReactElement {
  const { t } = useTranslation()

  const onChangeValue = (e: React.ChangeEvent): void => {
    const newValue = (e.target as HTMLInputElement).value
    const targetName = (e.target as HTMLInputElement).name

    if ( targetName === 'remarks' && newValue.length > 100 ) {
      return
    }

    onChangeRow({
      ...{
        uuid,
        programs,
        term,
        tools,
        industry,
        roles,
        phases,
        remarks,
      },
      [targetName]: newValue,
    })
  }

  const onChangeValues = (name: string, values: string[]): void => {
    onChangeRow({
      ...{
        uuid,
        programs,
        term,
        tools,
        industry,
        roles,
        phases,
        remarks,
      },
      [name]: values,
    })
  }

  const onClickDeleteBtn = (e: React.MouseEvent): void => {
    deleteRow(uuid)
  }

  return (
    <>
      <Row className="mb-4">
        <Col xs={12} md={4} className="mb-2 mb-md-0">
          <Form.Label>{t('project.label.program')}</Form.Label>
          <InputTags
            values={programs}
            onTags={(value) => onChangeValues('programs', value.values)}
          />
        </Col>
        <Col xs={12} md={4} className="mb-2 mb-md-0">
          <Form.Label>{t('project.label.term')}</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              name="term"
              value={term}
              onChange={onChangeValue}
            />
            <InputGroup.Text>{t('project.unit.term')}</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col xs={12} md={4}>
          <Form.Label>{t('project.label.tool')}</Form.Label>
          <InputTags
            values={tools}
            onTags={(value) => onChangeValues('tools', value.values)}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12} md={4} className="mb-2 mb-md-0">
          <Form.Label>{t('project.label.industry')}</Form.Label>
          <Form.Control
            type="text"
            value={industry}
            name="industry"
            onChange={onChangeValue}
            placeholder={t('project.placeholder.industry')}
          />
        </Col>
        <Col xs={12} md={4} className="mb-2 mb-md-0">
          <Form.Label>{t('project.label.role')}</Form.Label>
          <InputTags
            values={roles}
            onTags={(value) => onChangeValues('roles', value.values)}
          />
        </Col>
        <Col xs={12} md={4}>
          <Form.Label>{t('project.label.phase')}</Form.Label>
          <InputTags
            values={phases}
            onTags={(value) => onChangeValues('phases', value.values)}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12} md={11} className="mb-4 mb-md-0">
          <FloatingLabel label={t('project.label.remarks')}>
            <Form.Control
              as="textarea"
              name="remarks"
              value={remarks}
              onChange={onChangeValue}
            />
          </FloatingLabel>
        </Col>
        <Col
          xs={12}
          md={1}
          className="d-flex justify-content-center align-items-center"
        >
          <Button variant="light" onClick={onClickDeleteBtn}>
            <FaTrash />
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ProjectRow
