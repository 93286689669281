import { Experience } from 'interfaces'
import React from 'react'
import styled from 'styled-components'
import { adjustedExperienceGroups } from './calc'

interface GridSkillProps {
  height?: string
  column: number
  row: number
}

const GridSkill = styled.div<GridSkillProps>`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(${(props) => props.column}, 1fr);
  grid-template-rows: repeat(${(props) => props.row}, 1fr);
  /* 横向きのグリッドラインを決める */
  height: ${(props) => props.height ?? '350px'};
  /* 高さ指定 */
  gap: 10px;
`

interface GridSkillChildProps {
  color: string
  number: number
  column: string
  row: string
}

const GridSkillChild = styled.div<GridSkillChildProps>`
  :nth-child(${(props) => props.number}) {
    background-color: ${(props) => props.color};
    /* 背景色 */
    grid-column: ${(props) => props.column};
    /* 配置指定 */
    grid-row: ${(props) => props.row};
    /* 配置指定 */
  }
  padding: 10px;
  /* 余白指定 */
  border-radius: 5px;
  color: #fff;
  /* 文字色指定 */
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
`

interface SkillProps {
  experiences: Experience[]
}

const Skill = ({ experiences }: SkillProps): React.ReactElement => {
  const colors = [
    '#d4145a',
    '#d43d74',
    '#d45382',
    '#d4688f',
    '#d47d9d',
    '#d492aa',
    '#d4145a',
    '#d43d74',
    '#d45382',
    '#d4688f',
  ]

  const items = adjustedExperienceGroups(experiences, 2)
  // console.log(items)

  return (
    <GridSkill column={10} row={10}>
      {items.map((experience, i) => {
        return (
          <GridSkillChild
            key={i}
            number={i + 1}
            color={colors[i]}
            column={experience.column}
            row={experience.row}
          >
            {experience.name}
          </GridSkillChild>
        )
      })}
    </GridSkill>
  )
}

export default Skill
