import React from 'react'
import { Card } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import ExperienceGroup from '../../molecular/group/ExperienceGroup'
import { useSelecter } from 'stores'

function ExperienceArea(): React.ReactElement {
  const { t } = useTranslation()
  const { groups } = useSelecter((state) => {
    return state.experiences
  })

  return (
    <>
      <div className="card-header">
        <h1 className="c-title--head">{t('experience.name')}</h1>
        <p className="lead">{t('experience.description')}</p>
      </div>
      <Card className="mt-4">
        <Card.Body>
          {groups.map((group) => {
            return <ExperienceGroup key={group.index} {...group} />
          })}
        </Card.Body>
      </Card>
    </>
  )
}

export default ExperienceArea
