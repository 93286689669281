import React from 'react'
import { Project } from 'interfaces'
import logo from 'assets/images/footer_logo.svg'
import { useTranslation } from 'react-i18next'

interface ProjectProps {
  project: Project
}

interface ProjectsProps {
  projects: Project[]
}

const ProjectRow = ({ project }: ProjectProps): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      <div className="row c-project_box">
        <div className="col col8">
          <div className="c-box">
            <div className="c-box_list">
              <span className="c-box_label">{t('print.label.project.industry')}</span>
              <span>{project.industry}</span>
            </div>
          </div>
          <div className="c-box">
            <div className="c-box_list">
              <span className="c-box_label">{t('print.label.project.term')}</span>
              <span>
                {project.term}
                {t('print.label.project.term-unit')}
              </span>
            </div>
            <div className="c-box_list">
              <span className="c-box_label">{t('print.label.project.tool')}</span>
              <span>{project.tools?.join()}</span>
            </div>
          </div>
          <div className="c-box">
            <div className="c-box_list">
              <span className="c-box_label">{t('print.label.project.remarks')}</span>
              <span>{project.remarks}</span>
            </div>
          </div>
        </div>
        <div className="col col4">
          <div className="c-pickup_box">
            <div className="c-pickup c-basic_info">
              <div className="c-pickup_title">{t('print.label.project.program')}</div>
              <div className="c-pickup_text c-pickup_text__qualification">
                <div>{project.programs?.join()}</div>
              </div>
            </div>
            <div className="c-pickup c-basic_info">
              <div className="c-pickup_title">{t('print.label.project.role')}</div>
              <div className="c-pickup_text c-pickup_text__qualification">
                <div>{project.roles?.join()}</div>
              </div>
            </div>
            <div className="c-pickup c-basic_info">
              <div className="c-pickup_title">{t('print.label.project.phase')}</div>
              <div className="c-pickup_text c-pickup_text__qualification">
                <div>{project.phases?.join()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Projects = ({ projects }: ProjectsProps): React.ReactElement => {
  const { t } = useTranslation()
  
  return (
    <div className="preview-area">
      <section className="l-container c-page_break">
        <div className="l-inner">
          <div className="c-main--area">
            <h3 className="c-title--head">{t('project.name')}</h3>
            <div className="c-basic_area">
              {projects.map((project) => (
                <ProjectRow key={project.uuid} project={project} />
              ))}
            </div>
            <footer>
              <img src={logo} />
            </footer>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Projects
