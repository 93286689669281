import ProjectRow from 'components/molecular/row/ProjectRow'
import { Project } from 'interfaces'
import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { useDispatch, useSelecter } from 'stores'
import { updateProject, addProject, deleteProject } from 'stores/projects'
import { v4 as uuidv4 } from 'uuid'

function ProjectArea(): React.ReactElement {
  const { list } = useSelecter((state) => {
    return state.projects
  })
  const dispach = useDispatch()
  const { t } = useTranslation()

  const onChangeProjectRow = (project: Project): void => {
    dispach(updateProject(project))
  }

  const onClickAdd = (): void => {
    dispach(
      addProject({
        uuid: uuidv4(),
        programs: [],
        term: '1',
        tooles: [],
        industry: '',
        roles: [],
        phases: [],
        remarks: '',
      })
    )
  }

  const deleteRow = (uuid: string): void => {
    dispach(deleteProject({ uuid }))
  }

  return (
    <>
      <div className="card-header">
        <h1 className="c-title--head">{t('project.name')}</h1>
        <p className="lead">{t('project.description')}</p>
      </div>
      <Card className="mt-4 mb-4">
        <Card.Body>
          {list.map((project) => {
            return (
              <ProjectRow
                key={project.uuid}
                {...project}
                onChangeRow={onChangeProjectRow}
                deleteRow={deleteRow}
              />
            )
          })}
          <Row>
            <Col className="text-center">
              {list.length < 6 && (
                <Button variant="light" onClick={onClickAdd}>
                  <FaPlus />
                </Button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default ProjectArea
