import React from 'react'
import { Row, Col, Form, Button, Badge } from 'react-bootstrap'
import { Experience } from 'interfaces'
import { FaTrash } from 'react-icons/fa'

interface ExperienceProps extends Experience {
  onChangeRow: (experience: Experience) => void
  deleteRow: (uuid: string) => void
}

function ExperienceRow({
  uuid,
  name,
  value,
  onChangeRow,
  deleteRow,
}: ExperienceProps): React.ReactElement {
  const onChangeValue = (e: React.ChangeEvent): void => {
    const newValue = (e.target as HTMLInputElement).value
    const targetName = (e.target as HTMLInputElement).name

    onChangeRow({
      ...{
        uuid,
        name,
        value,
      },
      [targetName]: newValue,
    })
  }

  const onClickDeleteBtn = (e: React.MouseEvent): void => {
    deleteRow(uuid)
  }

  return (
    <>
      <Row className="mb-2">
        <Col xs={12} md={5} className="mb-4 mb-md-0">
          <Form.Control
            type="text"
            value={name}
            onChange={onChangeValue}
            name="name"
          />
        </Col>
        <Col xs={12} md={6} className="mb-4 mb-md-0 d-flex align-items-center">
          <Form.Range
            value={value}
            name="value"
            min={0}
            max={100}
            step={10}
            onChange={onChangeValue}
            style={{ display: 'inline-block', marginRight: 10 }}
          />
          <Badge pill bg="secondary">
            {value}
          </Badge>
        </Col>
        <Col xs={12} md={1} className="text-center">
          <Button variant="light" onClick={onClickDeleteBtn}>
            <FaTrash />
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ExperienceRow
