import React, { ChangeEvent, useRef, useState } from 'react'
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
} from './file-upload.styles'
import { useUpload } from 'services/hooks/upload'
import { useTranslation } from 'react-i18next'

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000

interface FileUploadProps {
  maxFileSizeInBytes?: number
  multiple?: boolean
  accept?: string[]
}

const FileUpload = ({
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  multiple = false,
  accept = ['yml', 'yaml'],
}: FileUploadProps): React.ReactElement => {
  const fileInputField = useRef<null | HTMLInputElement>(null)
  const [files, setFiles] = useState<{ [key: string]: File }>({})
  const { t } = useTranslation()

  const [uploadFile] = useUpload()

  const handleUploadBtnClick = (): void => {
    fileInputField.current?.click()
  }

  const addNewFiles = (newFiles: FileList | null): { [key: string]: File } => {
    if (newFiles === null) return files
    for (const file of newFiles) {
      if (!accept.includes(file.name.split('.').pop() ?? '')) {
        continue
      }

      if (file.size < maxFileSizeInBytes) {
        if (!multiple) {
          uploadFile(file)
          return { [file.name]: file }
        }

        files[file.name] = file
      }
    }
    return { ...files }
  }

  const handleNewFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files: newFiles } = event.target
    if (newFiles !== null && newFiles.length > 0) {
      const updateFiles = addNewFiles(newFiles)
      setFiles(updateFiles)
    }
  }

  return (
    <FileUploadContainer>
      <DragDropText>
        {Object.keys(files).length > 0 ? (
          <ul>
            {Object.keys(files).map((key) => (
              <li key={key}>{key}</li>
            ))}
          </ul>
        ) : (
          t('file-upload-modal.drag-and-drop-message')
        )}
      </DragDropText>
      <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
        <span>{t('file-upload-modal.upload-btn')}</span>
      </UploadFileBtn>
      <FormField
        type="file"
        title=""
        value=""
        ref={fileInputField}
        onChange={handleNewFileUpload}
      />
    </FileUploadContainer>
  )
}

export default FileUpload
