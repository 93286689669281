/* eslint-disable */
// @ts-nocheck
import { Experience } from 'interfaces'

export const createGroups = (
  experiences: Experience[],
  groupCount: number
): Experience[][] => {
  const groups: Experience[][] = []
  // const groupCount = 2

  experiences.forEach((experience: Experience) => {
    if (groupCount > groups.length) {
      groups.push([experience])
    } else {
      // グループごとの合算
      const groupsSum = groups.map((group) =>
        group.reduce((s, e) => s + Number(e.value), 0)
      )
      // グループごとの合算で最小のindex
      const minGroupIndex = groupsSum.indexOf(Math.min(...groupsSum))
      // 小さいグループに追加
      groups[minGroupIndex].push(experience)
    }
  })

  groups.forEach((g) => {
    g.sort((a, b) => Number(b.value) - Number(a.value))
  })

  return groups
}

export const adjustedExperienceGroups = (
  experiences: Experience[],
  groupCount
): any[] => {
  const groups = createGroups(experiences, groupCount)

  const count = 10

  // グループごとの横幅を決定
  const groupWidths: number[] = []
  const groupsSum = groups.map((group) =>
    group.reduce((s, o) => s + Number(o.value) / count, 0)
  )
  const total = groupsSum.reduce((s, v) => s + v, 0)

  groupsSum.forEach((sum, i) => {
    const width = Math.floor((sum / total) * count)

    // 最後の要素だけtotalに合うように調整
    if (i === groupsSum.length - 1) {
      groupWidths.push(count - groupWidths.reduce((s, w) => s + w, 0))
      return
    }

    groupWidths.push(width)
  })

  const organizeGroup = (group: any[]): any[] => {
    if (group.length <= 2) return group
    const newGroup = []
    const subGroup: Experience[] = []
    group.forEach((g, i) => {
      if (i === 0) {
        newGroup.push(g)
      } else {
        subGroup.push(g)
      }
    })
    // 再帰処理
    const organizedSubGroup = organizeGroup(subGroup)
    newGroup.push(organizedSubGroup)
    return newGroup
  }

  /**
   * グループごとの要素数が2より大きい場合
   * さらにサブグループ化
   */
  const organizedGroups = groups.map((group) => {
    if (group.length <= 2) {
      return group
    } else {
      return organizeGroup(group)
    }
  })

  /**
   * サブグループごとの再帰処理
   * @param isBeside 横向きかどうか
   * @param base このサブグループエリアの幅[横] or 高さ[縦]
   * @param fixedLenght すでに占めている長さ(幅[横] or 高さ[縦])
   * @param startIndex  塗りつぶし開始の座標[行, 列]
   * @param count 塗りつぶしフォーマットの長さ
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const adjustSubGroup = (
    target: any,
    isBeside: boolean,
    base: number,
    fixedLength: number,
    startIndex: number[],
    count: number
  ) => {
    const distances: number[] = [] // 算出した長さをストック(サブグループ内の幅or高さの記録)
    const adjustedSubGroup: any[] = [] // 調整後のサブグループストック

    // ↓この配列内の配列なんかを再帰的に合算するのをクールアンドビューティにしたい
    const subSum = target.reduce((sum: any, v: any) => {
      if (Array.isArray(v)) {
        return (
          sum +
          v.reduce((ss, vv) => {
            if (Array.isArray(vv)) {
              return (
                sum +
                vv.reduce((sss, vvv) => sss + Number(vvv.value) / count, 0)
              )
            } else {
              return ss + Number(vv.value) / count
            }
          }, 0)
        )
      } else {
        return sum + Number(v.value) / count
      }
    }, 0)
    // ↑この配列内の配列なんかを再帰的に合算するのをクールアンドビューティにしたい

    target.forEach((subTarget, k: number) => {
      if (k === 0) {
        const length = Math.round(
          (Number(subTarget.value) / count / subSum) * base
        )
        // const length = Math.floor(subTarget.value / subSum * base);
        distances.push(length)

        // どこから塗りつぶしを開始するかの座標[行, 列]
        const start = isBeside
          ? [startIndex[0], startIndex[1]]
          : [fixedLength, startIndex[1]]

        adjustedSubGroup.push({
          name: subTarget.name,
          value: subTarget.value,
          width: isBeside ? length : count - start[1],
          height: isBeside ? count - fixedLength : length,
          start,
        })
      } else if (!Array.isArray(subTarget) && target.length === 2 && k === 1) {
        // 要素数が2で最後のindexのみ
        // 最後の要素のため残りの長さをすべて持たせる
        const length = base - distances.reduce((s, w) => s + w, 0)

        const start = isBeside
          ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            [
              startIndex[0],
              distances.filter((_, l) => l < k).reduce((s, w) => s + w, 0) +
                startIndex[1],
            ]
          : [fixedLength + distances.reduce((s, w) => s + w, 0), startIndex[1]]
        adjustedSubGroup.push({
          name: subTarget.name,
          value: subTarget.value,
          width: isBeside ? length : count - start[1],
          height: isBeside ? 10 - fixedLength : length,
          start,
        })
      } else {
        // 要素数2以上でindexが1の場合 -> 再帰
        const direction = !isBeside // 次の塗りつぶす向き
        const args = {
          // base: direction ? base - distances.reduce((s, v) => s + v, 0) : count - fixedLength,
          base: direction ? count - startIndex[1] : count - fixedLength,
          fixedLength: direction
            ? distances.reduce((s, v) => s + v, 0) + fixedLength
            : startIndex[0],
        }
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const y = direction
          ? startIndex[0] + distances.reduce((s, v) => s + v, 0)
          : startIndex[0]
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const x = direction
          ? startIndex[1]
          : startIndex[1] + distances.reduce((s, v) => s + v, 0)
        const nextStartIndex = [y, x]
        const result = adjustSubGroup(
          subTarget,
          direction,
          args.base,
          args.fixedLength,
          nextStartIndex,
          count
        )
        adjustedSubGroup.push(result)
      }
    })
    return adjustedSubGroup
  }

  // グループごとに縦幅の配分を決定
  const adjustedGroups = organizedGroups.map((group, i) => {
    const heights: number[] = []
    // eslint-disable-next-line array-callback-return
    return group.map((target, j) => {
      // サブグループ
      if (Array.isArray(target)) {
        // 開始座標[行, 列]の生成
        const totalHeight = heights.reduce((s, h) => s + h, 0)
        const totalWidth = groupWidths
          .filter((_, gwi) => gwi < i)
          .reduce((sgw, gw) => sgw + gw, 0)
        const startIndex = [totalHeight, totalWidth] // 行NO, 列NO
        return adjustSubGroup(
          target,
          true,
          groupWidths[i],
          heights.reduce((s, h) => s + h, 0),
          startIndex,
          count
        )
      }

      if (j === 0) {
        const height = Math.floor(
          (Number(target.value) / count / groupsSum[i]) * count
        )
        heights.push(height)
        return {
          name: target.name,
          value: target.value,
          width: groupWidths[i],
          height,
          start: [
            0,
            i === 0
              ? 0
              : groupWidths
                  .filter((w, wi) => wi < i)
                  .reduce((s, w) => s + w, 0),
          ], // 塗りつぶしを開始する座標[行, 列]
        }
        // 最後の要素だけcountに合うように調整
      } else if (j === 1 && group.length === 2) {
        const heightTotal = heights.reduce((s, h) => s + h, 0)
        const height = count - heightTotal
        heights.push(height)
        return {
          name: target.name,
          value: target.value,
          width: groupWidths[i],
          height,
          start: [
            heightTotal,
            i === 0
              ? 0
              : groupWidths
                  .filter((w, wi) => wi < i)
                  .reduce((s, w) => s + w, 0),
          ],
        }
      }
    })
  })

  const items = adjustedGroups.flat(4)

  const result = items.map((item) => {
    if (item != null) {
      const rowStart = item.start[0] + 1
      const rowEnd = rowStart + item.height
      const columnStart = item.start[1] + 1
      const columnEnd = columnStart + item.width

      const column = `${columnStart}/${columnEnd}`
      const row = `${rowStart}/${rowEnd}`
      return {
        name: item.name,
        column,
        row,
      }
    }
  })

  return result
}
