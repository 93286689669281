import { createSlice } from '@reduxjs/toolkit'
import { Study } from 'interfaces'
import { v4 as uuidv4 } from 'uuid'

const initialState: {
  list: Study[]
} = {
  list: [
    {
      uuid: uuidv4(),
      name: '',
      date: '',
      remarks: '',
    },
  ],
}

const slice = createSlice({
  name: 'studies',
  initialState,
  reducers: {
    addStudy: (state, action) => {
      state.list = [
        ...state.list,
        {
          uuid: action.payload.uuid,
          name: action.payload.name,
          date: action.payload.date,
          remarks: action.payload.remarks,
        },
      ]
    },
    updateStudy: (state, action) => {
      state.list = state.list.map((study: Study) => {
        if (study.uuid === action.payload.uuid) {
          return {
            uuid: study.uuid,
            name: action.payload.name,
            date: action.payload.date,
            remarks: action.payload.remarks,
          }
        }
        return study
      })
    },
    deleteStudy: (state, action) => {
      state.list = state.list.filter(
        (study: Study) => study.uuid !== action.payload.uuid
      )
    },
    resetStudies: (state) => {
      state.list = initialState.list
    },
    clearStudies: (state) => {
      state.list = []
    },
  },
})

export const {
  addStudy,
  updateStudy,
  deleteStudy,
  resetStudies,
  clearStudies,
} = slice.actions

export default slice.reducer
