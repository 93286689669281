import data from 'assets/yaml/sample.yaml'
import { useUpload } from './upload'

export const useLoadSample = (): [() => void] => {
  const [uploadFile] = useUpload()

  const loadSample = (): void => {
    fetch(data)
      .then(async (res) => await res.blob())
      .then(async (blob) => new File([blob], 'sample.yaml'))
      .then(async (file) => {
        uploadFile(file)
        return file
      })
      .catch((err) => console.error(err))
  }

  return [loadSample]
}
