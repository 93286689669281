import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Experience } from '../../interfaces'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend)

interface StackedHorizontalBarProps {
  labels: string[]
  experiences: Experience[]
}

const colorMap: {
  [key: number]: string
} = {}

const selectedColors: {
  [key: string]: boolean
} = {}

const generateColor = (): string => {
  let randomColorString = '#'
  const arrayOfColorFunctions = '0123456789abcdef'
  for (let x = 0; x < 6; x++) {
    const index = Math.floor(Math.random() * 16)
    const value = arrayOfColorFunctions[index]

    randomColorString += value
  }
  return randomColorString
}

const newColorFind = (id: number): string => {
  // If already generated and assigned, return
  if (colorMap[id] !== undefined) return colorMap[id]

  // Generate new random color
  let newColor

  do {
    newColor = generateColor()
  } while (selectedColors[newColor])

  // Found a new random, unassigned color
  colorMap[id] = newColor
  selectedColors[newColor] = true

  // Return next new color
  return newColor
}

function StackedHorizontalBar({
  labels,
  experiences,
}: StackedHorizontalBarProps): React.ReactElement {
  const [data, setData] = useState<{
    labels: string[]
    datasets: Array<{
      label: string
      data: string[]
      backgroundColor: string
    }>
  }>({
    labels: [],
    datasets: [],
  })

  useEffect(() => {
    const datasets = experiences.map((experience, i) => {
      return {
        label: experience.name,
        data: [experience.value],
        backgroundColor: newColorFind(i),
      }
    })
    setData({
      labels,
      datasets,
    })
  }, [experiences])

  const options = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  return <Bar options={options} data={data} height={100} />
}

export default StackedHorizontalBar
