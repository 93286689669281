import React, { useEffect } from 'react'
import { Badge, Card, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelecter } from 'stores'
import { updateBasic } from 'stores/basic'
import { useForm } from 'react-hook-form'
import { Basic } from 'interfaces'

const BasicArea = (): React.ReactElement => {
  const { values } = useSelecter((state) => {
    return state.basic
  })
  const dispach = useDispatch()
  const { t } = useTranslation()

  const {
    register,
    formState: { errors },
    setFocus,
  } = useForm<Basic>({
    mode: 'onBlur',
  })

  useEffect(() => {
    setFocus('lastName')
  }, [])

  const onChangeValue = (e: React.ChangeEvent): void => {
    const newValue = (e.target as HTMLInputElement).value
    const targetName = (e.target as HTMLInputElement).name

    dispach(
      updateBasic({
        ...values,
        [targetName]: newValue,
      })
    )
  }

  return (
    <>
      <div className="card-header">
        <h1 className="c-title--head">{t('basic.name')}</h1>
      </div>
      <Card className="mt-4">
        <Card.Body>
          <Row className="mb-4">
            <Col xs={6} md={3} className="mb-2 mb-md-0">
              <Form.Label style={{ display: 'inline-block', marginRight: 5 }}>
                {t('basic.label.last-name')}
              </Form.Label>
              <Badge pill bg="danger">
                {t('common.required')}
              </Badge>
              <Form.Control
                type="text"
                placeholder={t('basic.placeholder.last-name')}
                value={values.lastName}
                {...register('lastName', {
                  required: t('validation.required'),
                  maxLength: { value: 20, message: t('validation.max.string') },
                  onChange: (e) => onChangeValue(e),
                })}
              />
              {errors.lastName !== undefined && (
                <Form.Text className="text-danger">
                  {errors.lastName.message}
                </Form.Text>
              )}
            </Col>
            <Col xs={6} md={3} className="mb-2 mb-md-0">
              <Form.Label style={{ display: 'inline-block', marginRight: 5 }}>
                {t('basic.label.first-name')}
              </Form.Label>
              <Badge pill bg="danger">
                {t('common.required')}
              </Badge>
              <Form.Control
                type="text"
                placeholder={t('basic.placeholder.first-name')}
                value={values.firstName}
                {...register('firstName', {
                  required: t('validation.required'),
                  maxLength: { value: 20, message: t('validation.max.string') },
                  onChange: (e) => onChangeValue(e),
                })}
              />
              {errors.firstName !== undefined && (
                <Form.Text className="text-danger">
                  {errors.firstName.message}
                </Form.Text>
              )}
            </Col>
            <Col xs={6} md={3} className="mb-2 mb-md-0">
              <Form.Label>{t('basic.label.initial')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('basic.placeholder.initial')}
                name="initial"
                value={values.initial}
                onChange={onChangeValue}
              />
            </Col>
            <Col xs={6} md={3} className="mb-2 mb-md-0">
              <Form.Label>{t('basic.label.gender.top')}</Form.Label>
              <div className="radio_adjust">
                {['man', 'woman'].map((value) => {
                  return (
                    <Form.Check
                      key={value}
                      inline
                      type="radio"
                      label={t(`basic.label.gender.${value}`)}
                      name="gender"
                      value={value}
                      checked={value === values.gender}
                      onChange={onChangeValue}
                    />
                  )
                })}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} className="mb-2 mb-md-0">
              <Form.Label style={{ display: 'inline-block', marginRight: 5 }}>
                {t('basic.label.birthday')}
              </Form.Label>
              <Badge pill bg="danger">
                {t('common.required')}
              </Badge>
              <Form.Control
                type="date"
                value={values.birthday}
                {...register('birthday', {
                  required: t('validation.required'),
                  onChange: (e) => onChangeValue(e),
                })}
                onFocus={(e) => {
                  if (values.birthday === '') {
                    e.target.value = '2000-01-01'
                  }
                }}
              />
              {errors.birthday !== undefined && (
                <Form.Text className="text-danger">
                  {errors.birthday.message}
                </Form.Text>
              )}
            </Col>
            <Col xs={12} md={4} className="mb-2 mb-md-0">
              <Form.Label style={{ display: 'inline-block', marginRight: 5 }}>
                {t('basic.label.station')}
              </Form.Label>
              <Badge pill bg="danger">
                {t('common.required')}
              </Badge>
              <Form.Control
                type="text"
                placeholder={t('basic.placeholder.station')}
                value={values.station}
                {...register('station', {
                  required: t('validation.required'),
                  onChange: (e) => onChangeValue(e),
                })}
              />
              {errors.station !== undefined && (
                <Form.Text className="text-danger">
                  {errors.station.message}
                </Form.Text>
              )}
            </Col>
            <Col xs={12} md={4}>
              <Form.Label>{t('basic.label.years')}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t('basic.placeholder.years')}
                name="years"
                value={values.years}
                onChange={onChangeValue}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default BasicArea
