import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook,
  useDispatch as rawUseDispatch,
  useSelector as rawUseSelector,
} from 'react-redux'
import experiences from './experiences'
import studies from './studies'
import projects from './projects'
import basic from './basic'

const reducer = combineReducers({
  experiences,
  studies,
  projects,
  basic,
})

const store = configureStore({ reducer, devTools: true })

export type RootState = ReturnType<typeof store.getState>

export const useSelecter: TypedUseSelectorHook<RootState> = rawUseSelector

export type AppDispatch = typeof store.dispatch
export const useDispatch = (): any => rawUseDispatch<AppDispatch>()

export default store
