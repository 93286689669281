import React from 'react'
import 'css/print/preview.scss'
import 'css/print/print.css'
import logo from 'assets/images/footer_logo.svg'
import { useSelecter } from 'stores'
import { useTranslation } from 'react-i18next'
import Skill from './grid/Skill'
import Phase from './grid/Phase'
import Roll from './grid/Roll'

interface StudyBoxProps {
  name: string
  date: string
}

const StudyBox = ({ name, date }: StudyBoxProps): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="c-box">
      <div className="c-box_list">
        <span className="c-box_label">{t('study.label.date')}</span>
        <span>{date}</span>
      </div>
      <div className="c-box_list">
        <span className="c-box_label">{t('study.label.name')}</span>
        <span>{name}</span>
      </div>
    </div>
  )
}

interface OverviewProps {
  identification: string
}

function Overview({ identification }: OverviewProps): React.ReactElement {
  const { basic, studies, experiences } = useSelecter((state) => {
    return state
  })
  const { t } = useTranslation()

  const getAge = (): number => {
    if (basic.values.birthday === '') return 0
    const today = new Date()
    const birthday = new Date(basic.values.birthday)
    const thisBirthday = new Date(
      today.getFullYear(),
      birthday.getMonth(),
      birthday.getDate()
    )
    return (
      today.getFullYear() -
      birthday.getFullYear() -
      (today < thisBirthday ? 1 : 0)
    )
  }

  return (
    <div className="preview-area">
      <section className="l-container">
        <div className="l-inner">
          <div className="c-main--area">
            <h3 className="c-title--head">{t('basic.name')}</h3>
            <div className="c-basic_area">
              <div className="row">
                <div className="col col8">
                  <div className="c-box">
                    <div className="c-box_list">
                      <span className="c-box_label">
                        {identification === 'name'
                          ? t('print.label.basic.name')
                          : t('print.label.basic.initial')}
                      </span>
                      <span>
                        {identification === 'name'
                          ? `${basic.values.lastName} ${basic.values.firstName}`
                          : basic.values.initial}
                      </span>
                    </div>
                    <div className="c-box_list">
                      <span className="c-box_label">
                        {t('print.label.basic.gender')}
                      </span>
                      <span>
                        {t('basic.label.gender.' + basic.values.gender)}
                      </span>
                    </div>
                    <div className="c-box_list">
                      <span className="c-box_label">
                        {t('print.label.basic.age')}
                      </span>
                      <span>
                        {getAge()}
                        {t('print.label.basic.old')}
                      </span>
                    </div>
                  </div>
                  <div className="c-box">
                    <div className="c-box_list">
                      <span className="c-box_label">
                        {t('print.label.basic.station')}
                      </span>
                      <span>{basic.values.station}</span>
                    </div>
                  </div>
                </div>
                <div className="col col4">
                  <div className="c-pickup_box">
                    <div className="c-pickup c-basic_info">
                      <div className="c-pickup_title">
                        {t('print.label.basic.exp')}
                      </div>
                      <div className="c-pickup_text">
                        <div>
                          <span>{basic.values.years}</span>
                          {t('print.label.basic.year')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="c-title--head">{t('study.name')}</h3>
            <div className="c-basic_area">
              <div className="row">
                <div className="col col12">
                  {studies.list.map((study) => {
                    return (
                      <StudyBox
                        key={study.uuid}
                        name={study.name}
                        date={study.date ?? ''}
                      />
                    )
                  })}
                </div>
              </div>
            </div>

            <h3 className="c-title--head">{t('experience.name')}</h3>
            <div className="c-basic_area">
              <div className="grid_box">
                <Skill experiences={experiences.groups[0].experiences} />
                <Phase experiences={experiences.groups[1].experiences} />
                <Roll experiences={experiences.groups[2].experiences} />
              </div>
            </div>

            <footer>
              <img src={logo} />
            </footer>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Overview
