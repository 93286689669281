import { createSlice } from '@reduxjs/toolkit'
import i18next from 'i18n/configs'
import { ExperienceGroup } from 'interfaces'

const initialState: {
  groups: ExperienceGroup[]
} = {
  groups: [
    {
      index: 0,
      title: i18next.t('experience.label.program'),
      experiences: [],
    },
    {
      index: 1,
      title: i18next.t('experience.label.phase'),
      experiences: [],
    },
    {
      index: 2,
      title: i18next.t('experience.label.role'),
      experiences: [],
    },
  ],
}

const slice = createSlice({
  name: 'experiences',
  initialState,
  reducers: {
    updateExperienceGroup: (state, action) => {
      state.groups = state.groups.map((group: ExperienceGroup) => {
        if (group.index === action.payload.index) {
          return {
            index: group.index,
            title: action.payload.title,
            experiences: action.payload.experiences,
          }
        }
        return {
          index: group.index,
          title: group.title,
          experiences: group.experiences,
        }
      })
    },
    resetExperienceGroups: (state) => {
      state.groups = initialState.groups
    },
  },
})

export const { updateExperienceGroup, resetExperienceGroups } = slice.actions

export default slice.reducer
