import { FileFormat } from 'interfaces'
import { useDispatch } from 'stores'
import yaml from 'js-yaml'
import { updateBasic } from 'stores/basic'
import { addStudy, clearStudies } from 'stores/studies'
import { updateExperienceGroup } from 'stores/experiences'
import { addProject, clearProjects } from 'stores/projects'

export const useUpload = (): [(file: File) => void] => {
  const dispach = useDispatch()

  const uploadFile = (file: File): void => {
    file
      .text()
      .then((text) => {
        const fileFormat = yaml.load(text) as FileFormat

        if (fileFormat.studies.length > 0) {
          dispach(clearStudies())
        }

        if (fileFormat.projects.length > 0) {
          dispach(clearProjects())
        }

        dispach(updateBasic(fileFormat.basic))

        fileFormat.studies.forEach((study) => {
          dispach(addStudy(study))
        })

        fileFormat.experienceGroups.forEach((experienceGroup) => {
          dispach(updateExperienceGroup(experienceGroup))
        })

        fileFormat.projects.forEach((project) => {
          dispach(addProject(project))
        })
      })
      .catch((e) => console.error(e))
  }

  return [uploadFile]
}
