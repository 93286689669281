import { createSlice } from '@reduxjs/toolkit'
import { Basic } from 'interfaces'

const initialState: {
  values: Basic
} = {
  values: {
    lastName: '',
    firstName: '',
    initial: '',
    gender: 'man',
    birthday: '',
    station: '',
    years: '1',
  },
}

const slice = createSlice({
  name: 'basic',
  initialState,
  reducers: {
    updateBasic: (state, action) => {
      state.values = action.payload
    },
    resetBasic: (state) => {
      state.values = initialState.values
    },
  },
})

export const { updateBasic, resetBasic } = slice.actions

export default slice.reducer
